import React from 'react'
import styled from 'styled-components'

import { Paper } from '../../atoms/Paper/Paper'
import { Header } from '../../atoms/Typography/Header'
import { Text } from '../../atoms/Typography/Text'
import { SectionWrapper } from '../../molecules/SectionWrapper/SectionWrapper'
import { Breakpoints } from '../../styles/breakpoints'
import { Colors } from '../../styles/colors'
import { Bold } from '../../styles/common'
import { Spacings } from '../../styles/spacings'
import { Treatment } from './Treatment'

const Layout = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'auto',
  gridColumnGap: '30px',

  [Breakpoints.large]: {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridRowGap: '30px',
  },

  [Breakpoints.small]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(3, 1fr)',
    gridRowGap: '30px',
  },
})

const HeadingSpace = styled.div`
  margin-bottom: ${Spacings['52']};
`

const BlackText = styled(Text)`
  color: ${Colors.black};
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledHeader = styled(Header)`
  text-align: center;
`

export const Prices = () => {
  return (
    <SectionWrapper
      background={`linear-gradient(0deg, ${Colors.white} 65%, ${Colors.lighterGrey} 65%);`}
      paddingTop
      paddingBottom
    >
      <StyledHeader variant='heading2'>Cennik</StyledHeader>
      <Layout>
        <Paper>
          <FlexWrapper>
            <HeadingSpace>
              <BlackText font='Roboto' variant='xLarge'>
                <Bold>Fizykoterapia</Bold>
              </BlackText>
            </HeadingSpace>
            <Treatment treatment='Zabieg prądowy' price='20 zł' />
            <Treatment treatment='Pole magnetyczne' price='20 zł' />
            <Treatment treatment='Laser' price='20 zł' />
            <Treatment treatment='Lampa Sollux' price='20 zł' />
            <Treatment treatment='Światło spolaryzowane' price='20 zł' />
            <Treatment treatment='Krioterapia miejscowa' price='25 zł' />
            <Treatment treatment='Ultradzwięki / Fonoforeza' price='25 zł' />
          </FlexWrapper>
        </Paper>
        <Paper>
          <FlexWrapper>
            <HeadingSpace>
              <BlackText font='Roboto' variant='xLarge'>
                <Bold>Kinezyterapia</Bold>
              </BlackText>
            </HeadingSpace>
            <Treatment treatment='Ćwiczenia:' price='' bold />
            <Treatment treatment='Ogólnousprawniające' price='50 zł' />
            <Treatment treatment='W odciążeniu' price='30 zł' />
            <Treatment treatment='Samowspomagane' price='30 zł' />
            <Treatment treatment='Specjalistyczne' price='30 min / 100 zł' secondPrice='60 min / 150 zł' />
            <Treatment treatment='Metody specjalne:' price='' bold />
            <Treatment treatment='Terapia manualna' price='30 min / 100 zł' secondPrice='60 min / 150 zł' />
            <Treatment treatment='Fizjoterapia uroginekologiczna' price='150 zł' />
          </FlexWrapper>
        </Paper>
        <Paper>
          <FlexWrapper>
            <HeadingSpace>
              <BlackText font='Roboto' variant='xLarge'>
                <Bold>Inne</Bold>
              </BlackText>
            </HeadingSpace>
            <Treatment treatment='Porada Lekarza Specjalisty Rehabilitacji' price='200 zł' />
            <Treatment treatment='Konsultacja fizjoterapeutyczna' price='150 zł' />
            <Treatment treatment='Kinesiotaping' price='40 zł - 60 zł' />
            <Treatment treatment='Masaż' price='15 min / 60 zł' secondPrice='30 min / 90 zł' />
          </FlexWrapper>
        </Paper>
      </Layout>
    </SectionWrapper>
  )
}
