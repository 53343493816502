import React from 'react'

import SEO from '../components/seo'
import Main from '../layouts/main'
import { BigHeader } from '../molecules/BigHeader/BigHeader'
import { HeaderAndText } from '../organisms/HeaderAndText/HeaderAndText'
import { OnlyText } from '../organisms/HeaderAndText/OnlyText'
import { Prices } from '../organisms/Prices/Prices'

const WizytyPrywatnePage = () => (
  <Main>
    <SEO title='Poradnia Rehabilitacyjna Azory' />
    <BigHeader headerText='Wizyty prywatne' />
    <OnlyText contentText='Śledząc na bieżąco postępy w fizjoterapii jesteśmy w stanie zaoferować skuteczne metody leczenia.' />
    <Prices />
    <HeaderAndText
      headerText='Jak przygotować się na zabiegi?'
      buttonText='Umów się na zabieg'
      contentText={
        <>
          1. Przygotuj się na opisanie swojego problemu. Weź ze sobą badania obrazowe, jeżeli takie posiadasz.
          <br />
          <br />
          2. Zadbaj o swoją higienę.
          <br />
          <br />
          3. Ubierz się wygodnie i weź ze sobą obuwie zamienne. Możesz też zabrać ze sobą swój ulubiony ręcznik.
          <br />
          <br />
          4. Bądź punktualnie. Dokładamy wszelkich starań, aby przyjąć Cię o wyznaczonej godzinie. Jednak czasem wizyty
          się wydłużają. W takiej sytuacji bądź wyrozumiały i poczekaj cierpliwie. Na pewno zostaniesz przyjęty.
        </>
      }
      buttonLink='/kontakt'
    />
  </Main>
)

export default WizytyPrywatnePage
